import axios from 'axios';
import qs from 'qs'
import {ElMessage,ElLoading } from 'element-plus'
let loadingInstance = null;
//创建loading
const options = {
	fullscreen: true ,
	target:""
}
// 创建axios实例
const Axios = axios.create({
	// baseUrl:"/api",
	// timeout:50000,
	// headers:{
	// 	'content-type': 'application/json'
	// }
});

Axios.defaults.timeout = 50000; // 超时时间
Axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.responseType = 'json'; // 返回数据类型


Axios.interceptors.request.use(config => {
config.headers['satoken'] = sessionStorage.getItem("tokenInfo")==""||sessionStorage.getItem("tokenInfo")==null?"":JSON.parse(sessionStorage.getItem("tokenInfo")).tokenValue
	if (!config.headers['Content-Type']) {
		if (config.method === "post" || config.method === "get" || config.method === "delete") {
			// 序列化
			// config.data = qs.stringify(config.data);
		}
	}
	if(config.loading!=""){
		options.target = config.loading
		loadingInstance = ElLoading.service(options);
	}
	return config;
}, error => {
	let msg = "请求数据失败！";
	ElMessage.warning({
		message: msg,
		type: 'warning'
	});
	return Promise.reject(msg)
});

// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(res => {
	console.log("123sadascfz++++====>"+res);
	let resData = res.data;
	if(loadingInstance!=null){
		loadingInstance.close();
	}
	if (resData.code == 200) {
		return resData;
	} else {
		ElMessage.error({
			message: resData.msg,
			type: 'warning'
		});
		return Promise.reject(resData.msg)
	}
}, error => {
	if(loadingInstance!=null){
		loadingInstance.close();
	}
	ElMessage.error({
		message: res.data.msg,
		type: 'warning'
	});
});

export default Axios;

import {
	createStore
} from 'vuex'


export default createStore({

	state() {
		return {
			userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '',
			tokenInfo: sessionStorage.getItem('tokenInfo') ? JSON.parse(sessionStorage.getItem('tokenInfo')) : '',
			isLogin: sessionStorage.getItem('isLogin') && sessionStorage.getItem('isLogin') == 'true' ? 'true' : 'false'
		};
	},

	mutations: {
		setUserInfo(state, userInfo) {
			console.log('我是西南西', userInfo)
			sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
			state.userInfo = userInfo
		},
		setTokenInfo(state, tokenInfo) {
			sessionStorage.setItem('tokenInfo', JSON.stringify(tokenInfo))
			state.tokenInfo = tokenInfo
		},
		setIsLogin(state, isLogin) {
			console.log('登录成功')
			sessionStorage.setItem('isLogin', isLogin)
			state.isLogin = isLogin
		}

	},
	getters: {
		getUserInfo: state => state.userInfo,
		getTokenInfo: state => state.tokenInfo,
		getIsLogin: state => state.isLogin
	},
	

});

import request from './request'

// const baseUrl = "http://127.0.0.1:8066/";
// const baseUrl = "http://103.120.82.111:8066/";

const baseUrl = "/joi/";
const http = {
	/**
	 * methods: 请求
	 * @param url 请求地址 
	 * @param params 请求参数
	 */


	get(url, params, loading) {
		const config = {
			method: 'get',
			url: baseUrl + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.params = params
		return request(config)
	},
	post(url, params, loading) {
		const config = {
			method: 'post',
			url: baseUrl + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.data = params
		return request(config)
	},
	postP(url, params, loading) {
		const config = {
			method: 'post',
			url: 'https://www.jiansuobao.net/retriveal/EIQueryJournalism',
			loading: loading == undefined ? "" : "." + loading
		}
		// if(params) config.data = params
		config.data = params
		return request(config)
	},
	put(url, params, loading) {
		const config = {
			method: 'put',
			url: baseUrl + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.params = params
		return request(config)
	},
	delete(url, params, loading) {
		const config = {
			method: 'delete',
			url: baseUrl + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.params = params
		return request(config)
	},
	loginCode(url, params, loading) {
		const config = {
			method: 'get',
			url: 'http://47.97.203.109:99/api/msm/send/' + url,
			loading: loading == undefined ? "" : "." + loading
		}
		// if(params) config.data = params
		config.data = params
		return request(config)
	}
}
//导出
export default http
<template>
	<div>
		<div>
			<div style="background-color: #18305B; color: white;height: 50px;">
				<div style="width: 1200px;height: 50px;display: flex;margin: auto;">
					<div>
						<p>
							<span style="margin-right: 20px;"><i class="el-icon-phone-outline"
									style="margin-right: 10px;"></i>0371-61319290 / 0371-63360580</span>
							<span><i class="el-icon-message" style="margin-right: 10px;"></i>service@iamset.cn</span>
						</p>
					</div>
					<div style="margin-left: auto;">
						<div class="metting-head-login" v-if="getIsLogin == 'true'" style="padding-top: 7%;">
							<el-avatar :size="40" style="float: left;margin-top: -14px;" :src="getUserInfo.avatar">
							</el-avatar>
							<el-divider direction="vertical"></el-divider>
							<el-link :underline="false" href="#" style="color: white;">{{ getUserInfo.name }}</el-link>
							<el-divider direction="vertical"></el-divider>
							<el-link :underline="false" href="#" @click="loginOut()" style="color: white;">退出登录
							</el-link>
						</div>
						<div class="metting-head-login" v-else>
							<span><i class="el-icon-user-solid"></i></span>
							<span @click="login" style="color: #ff5722;cursor: pointer;font-size: 14px;">登录</span>
							<el-divider direction="vertical"></el-divider>
							<el-link :underline="false" href="/phoneRg" style="color: #ff5722;">注册</el-link>
						</div>
					</div>
				</div>
			</div>
			<div style="display: flex;margin: 15px auto;width: 1200px;">
				<div class="metting-head-left">
					<el-image :src="require('@/assets/images/logo.png')" style="width: 100%;height: 70%"></el-image>
				</div>
				<div class="metting-head-right">
					<div class="mettingNav">
						<!-- mode="horizontal" vertical -->
						<el-menu :default-active="activeIndex" :router="routerR" class="el-menu-demo"
							active-text-color="#1b5faa" mode="horizontal" menu-trigger="click" unique-opened="true">
							<el-menu-item index="/index">首页</el-menu-item>
							<el-menu-item index="/meeting">学术会议</el-menu-item>
							<el-submenu index="journal">
								<template #title class="journal">期刊索引</template>
								<!-- <el-menu-item index="journalEi">EI期刊</el-menu-item> -->
								<el-menu-item index="journalSci">SCI期刊&SSCI期刊</el-menu-item>
								<!-- <el-menu-item index="journalSsci">SSCI期刊</el-menu-item> -->
								<el-menu-item index="journalAHCI">AHCI期刊</el-menu-item>
								<el-menu-item index="journalEsci">ESCI期刊</el-menu-item>
								<!-- <el-menu-item index="journalEn">英文普刊</el-menu-item> -->

							</el-submenu>
							<el-submenu index="service">
								<template #title>服务项目</template>
								<el-menu-item index="translation">论文翻译</el-menu-item>
								<el-menu-item index="touchUp">论文润色</el-menu-item>
								<el-menu-item index="duplicate">查重服务</el-menu-item>
								<el-menu-item index="searchProof">检索证明</el-menu-item>
							</el-submenu>
							<el-submenu index="notice">
								<template #title>学术动态</template>
								<el-menu-item index="learning">科研要闻</el-menu-item>
								<el-menu-item index="important">干货分享</el-menu-item>
								<!-- <el-menu-item index="progress">论文进展</el-menu-item> -->
								<el-menu-item index="Journalism">协会新闻</el-menu-item>
								<el-menu-item index="resourceDow">资源下载</el-menu-item>

							</el-submenu>
							<el-menu-item index="contribution">在线匹配</el-menu-item>
							<el-menu-item index="problem">常见问题</el-menu-item>
							<el-menu-item index="about">关于我们</el-menu-item>
						</el-menu>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import $http from "@/request/http";
// import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
export default {
	name: "app-header",
	data() {
		return {
			routerR: true,
			activeIndex: "/index",
		};
	},
	watch: {
		// 监听路由变化
		"$route.path": {
			handler(routePath) {
				this.initMenuActive(routePath);
			},
			immediate: true,
		},
		getIsLogin(value) {
			console.log(value, 'getIsLogin')
		},
		getUserInfo(value) {
			console.log(value, 'getUserInfo')
		}
	},
	computed: {
		...mapGetters(['getIsLogin', 'getUserInfo'])
	},
	methods: {
		initMenuActive(routePath) {
			//路由跳转时，标记在导航的哪个选项下对应的路由，css高亮显示
			// if (routePath == "/about") {
			//   this.login = false;
			// }
			this.activeIndex = routePath;
			if (routePath == '/') {
				this.activeIndex = '/index'
			}
		},
		loginOut() {
			let _this = this
			this.$store.commit('setTokenInfo', "")
			this.$store.commit('setUserInfo', "")
			this.$store.commit('setIsLogin', "false")
			$http.post("loginOut")
				.then(function (res) {
					if (res.code == 200) {
						_this.$message.success({
							showClose: true,
							duration: 1500,
							message: res.msg,
						});
					}
				})
				.catch(function () { });
		},
		login() {

			this.$router.push('/login')
			// setTimeout(() => {
			// 	this.$router.go(0)
			// }, 500)
		}
		// setInitState(){
		//  	this.$store.commit('setTokenInfo', sessionStorage.getItem('tokenInfo') ? this.pa)
		// 	this.$store.commit('setUserInfo', sessionStorage.getItem('userInfo'))
		// 	this.$store.commit('setIsLogin', sessionStorage.getItem('isLogin'))
		// }
	},
	created() {
	}

};
</script>
<style scoped lang="scss">
.metting-head-left {
	width: 16%;
	display: flex;
	align-items: center;
}

.metting-head-right {
	padding-left: 38px;
	width: 90%;
}

.metting-head-login {
	padding-top: 17%;

	i {
		font-size: 20px;
	}

	span {
		margin-right: 8px;
		color: #ff5722;
	}
}


.mettingNav {
	.el-menu-item {
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		color: black;
	}
}

.el-menu.el-menu--horizontal {
	border-bottom: none;
}

// .divider {
// 	height: 4px;
// 	background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
// 		linear-gradient(#1b5faa, #1b5faa);
// 	background-blend-mode: normal, normal;
// 	box-shadow: 0px 3px 4px 0px rgb(43 43 43 / 64%);
// }</style>
<style>
.el-menu--popup {
	z-index: 100;
	min-width: 105px;
	border: none;
	padding: 5px;
	border-radius: var(--el-border-radius-small);
	-webkit-box-shadow: var(--el-box-shadow-light);
	box-shadow: var(--el-box-shadow-light);
}

.el-submenu__title {
	text-align: center;
	/* border-bottom: none; */
	font-size: 16px;
	font-weight: 500;
}

.el-menu-item {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	color: black;
}

.el-menu--horizontal>.el-submenu .el-submenu__title {
	color: black;
}
</style>

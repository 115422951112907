import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
// import 'element-plus/dist/index.css'
import "element-plus/lib/theme-chalk/index.css";
import 'element-plus/lib/theme-chalk/display.css';
import "./assets/css/style.css";
import axios from "axios";
import VueAxios from "vue-axios";
import {
	createApp
} from "vue";
import ECharts from "vue-echarts";
import App from "./App.vue";

const app = createApp(App);

// Vue.prototype.resetSetItem = function (key, newVal) {
//   // if (key === 'xxxx') {
//     // 创建一个StorageEvent事件
//     var newStorageEvent = document.createEvent('StorageEvent');
//     const storage = {
//       setItem: function (k, val) {
//         sessionStorage.setItem(k, val);
//         // 初始化创建的事件
//         newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
//         // 派发对象
//         window.dispatchEvent(newStorageEvent)
//       }
//     }
//     return storage.setItem(key, newVal);
//   // }
// }


app.use(store)
	.use(router)
	.use(ElementPlus)
	.use(VueAxios, axios)
	.use(ECharts)
	.mount("#app");

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

<template>
	<div class="metting-bottom">
		<div style="padding-top: 20px;width: 80%;margin: auto;">
			<el-divider style="background-color: #ff5722;;"></el-divider>
		</div>
		<div class="metting-bottom-1">
			<div class="metting-bottom-2">
				<div>
					<el-image :src="require('@/assets/images/logo2.png')" style="width: 179px;"></el-image>
				</div>

			</div>
			<div class="metting-bottom-2">
				<p style="font-size: 16px; color: #add6ff; padding-bottom: 10px">
					服务项目
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/journalEi">期刊索引</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/meeting">学术会议</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/translation">论文翻译</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/touchUp">论文润色</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/duplicate">查重服务</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/searchProof">检索证明</el-link>
				</p>
			</div>
			<div class="metting-bottom-2">
				<p style="font-size: 16px; color: #add6ff; padding-bottom: 10px">
					关于IAMSET
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/about">关于我们</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/learning">协会通告</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/resourceDow">资源下载</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="/problem">常见问题</el-link>
				</p>

			</div>
			<div class="metting-bottom-2">
				<p>
					<i class="el-icon-phone-outline" style="margin-right: 10px;"></i>投稿热线
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">0371-63360580</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">0371-61319290</el-link>
				</p>
				<p>
					<i class="el-icon-message" style="margin-right: 10px;"></i>投稿邮箱
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">service@iamset.cn</el-link>
				</p>
			</div>
			<div class="metting-bottom-3" style="margin-top: 1%;">
				<div>
					<el-image :src="require('@/assets/images/service.jpg')" style="width: 140px; height: 140px">
					</el-image>
					<div style="text-align: center;">
						<p style="font-size: 14px;">关注IAMSET公众号</p>
						<p style="color: #ff5722;;font-weight: 700;">下单立减200元</p>
					</div>
				</div>

			</div>
		</div>
		<div class="metting-bottom-4">

			<p>Copyright © 2010-2024 IAMSET艾慕赛特 版权所有 All Rights Reserved.</p>
			<p>Registered Address: HK: 11/F Rykadan Capital Tower 135-137 HOI Bun Road, Kwun Tong KL</p>
			<p><a style="color:#add6ff;" href="http://beian.miit.gov.cn">豫ICP备2024092800号</a></p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "app-footer",
		data() {
			return {};
		},
	};
</script>
<style scoped lang="scss">
	.metting-bottom {
		height: 350px;
		color: white;
		background-image: url("../assets/images/index_bottom.jpg");
		border: 0 none;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.metting-bottom-1 {
		display: flex;
		line-height: 7px;
		// padding-top: 4%;
		margin-left: 20%;
	}

	.metting-bottom-2 {
		margin-right: 4%;
	}

	.el-link.el-link--default {
		color: white;
	}

	.elLink:hover {
		color: #add6ff;
	}

	.metting-bottom-3 {
		display: flex;
		margin-left: 20%;
		margin-top: 5%;
	}

	.metting-bottom-4 {
		color: #add6ff;
		line-height: 7px;
		margin-left: 33%;
		font-size: 10px;
	}
</style>
<template>
	<div class="metting">
		<app-header v-if="header_show" @search="searchKey"></app-header>
		<div class="navContent" v-bind:class="{ navContentWidth: header_show }">
			<div style="height: 0px;" v-if="header_show">
				<el-affix :offset="620" v-if="isShowQR" style="margin-left: 86%;">
					<el-image style="" :src="require('@/assets/images/ma_03.png')"></el-image>
				</el-affix>
				<el-affix :offset="620" style="width: 100px;margin-left: 94%;">
					<div style="">
						<div>
							<!-- a target="_blank"
								href="http://wpa.qq.com/msgrd?v=3&amp;uin=1044660476&amp;site=qq&amp;menu=yes"><img
									border="0" :src="require('@/assets/images/right1.png')" alt="点击这里给我发消息"
									title="点击这里给我发消息" /></a> -->
							<a href="javascript:void(0)" @click="returnKefu()"><img border="0"
									:src="require('@/assets/images/right1.png')" alt="点击这里给我发消息"
									title="点击这里给我发消息" /></a>
						</div>
						<div @mouseover="mouseOverQR()" @mouseleave="mouseLeaveQR()">
							<el-image style="" :src="require('@/assets/images/right3.png')"></el-image>
						</div>
						<div @click="returnTop()">
							<el-image style="" :src="require('@/assets/images/right2.png')"></el-image>
						</div>
					</div>
				</el-affix>
			</div>
			<router-view v-on:header="header" v-on:footer="footer" :searchVal="searchVal" v-if="isRouterAlive"></router-view>
		</div>
		<app-footer v-if="footer_show"></app-footer>
	</div>
</template>
<script>
	import Header from "./components/meetingHead";
	import Footer from "./components/meetingBottom";
	export default {
		
		data() {
			return {
				isShowQR: false,
				searchVal: '',
				header_show: true,
				footer_show: true,
				
				//路由重载
				isRouterAlive: true
			};
		},
		components: {
			"app-header": Header,
			"app-footer": Footer,
		},
		 provide(){
		    return{
		      reload:this.reload
		    }
		  },
		methods: {
			mouseOverQR() {
				this.isShowQR = true
			},
			mouseLeaveQR() {
				this.isShowQR = false
			},
			//是否显示头部
			header: function(bool) {
				this.header_show = bool;
			},
			//是否显示底部
			footer: function(bool) {
				this.footer_show = bool;
			},
			searchKey(val) {
				this.searchVal = val
			},
			returnTop() {
				var timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 50)
			},
			returnKefu() {
				window.open('http://wpa.qq.com/msgrd?v=3&uin=' + 2372522993 + '&site=qq&menu=yes', '_brank');
			},
			 reload(){
			      this.isRouterAlive = false
			      this.$nextTick(function(){
			        this.isRouterAlive = true
			      })
			    }

		},
	};
</script>

<style lang="scss" scoped>
	/* 导航下页面 */
	.metting {
		// padding-top: 2%;
	}

	.navContent {
		// margin: 0 auto;
		color: #333333;
		min-height: 780px;
		padding-bottom: 30px;
		background-color: #fff;
	}

	.navContentWidth {
		width: 100%;
	}
</style>

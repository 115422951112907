import {
	createRouter,
	createWebHistory
} from "vue-router"; //createWebHashHistory
import home from "../views/index.vue";

const routes = [{
		path: "",
		name: "",
		component: () => import("../views/index.vue"),
	},
	{
		path: "/index",
		name: "index",
		component: home,
		meta: {
			index: 1,
			title: '国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/login",
		name: "login",
		component: () => import("../views/login.vue"),
		meta: {
			index: 2,
			title: '登录/注册-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/phoneRg",
		name: "phoneRg",
		component: () => import("../views/login.vue"),
		meta: {
			index: 3,
			title: '登录/注册-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/meeting",
		name: "meeting",
		component: () => import("../views/meeting/meeting.vue"),
		meta: {
			index: 4,
			title: '学术会议-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/meetingDetails",
		name: "meetingDetails",
		component: () => import("../views/meeting/meetingDetails.vue"),
		meta: {
			index: 5,
			title: '学术会议-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/journalEi",
		name: "journalEi",
		component: () => import("../views/journal/journalEi.vue"),
		meta: {
			index: 6,
			title: 'EI期刊-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/journalSci",
		name: "journalSci",
		component: () => import("../views/journal/journalSci.vue"),
		meta: {
			index: 7,
			title: 'SCI期刊-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/journalSsci",
		name: "journalSsci",
		component: () => import("../views/journal/journalSsci.vue"),
		meta: {
			index: 8,
			title: 'SSCI期刊-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/journalEn",
		name: "journalEn",
		component: () => import("../views/journal/journalEn.vue"),
		meta: {
			index: 9,
			title: '英文普刊-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/journalAhci",
		name: "journalAhci",
		component: () => import("../views/journal/journalAhci.vue"),
		meta: {
			index: 26,
			title: 'AHCI期刊-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/journalEsci",
		name: "journalEsci",
		component: () => import("../views/journal/journalEsci.vue"),
		meta: {
			index: 26,
			title: 'EHCI期刊-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/journalDetails",
		name: "journalDetails",
		component: () => import("../views/journal/journalDetails.vue"),
		meta: {
			index: 10,
			title: 'SCI&SSCI期刊详情-国际管理科学与工程技术协会（IAMSET）',
		},
	},
	{
		path: "/journalDetailsAHCI",
		name: "journalDetailsAHCI",
		component: () => import("../views/journal/journalDetailsAHCI.vue"),
		meta: {
			index: 111,
			title: 'AHCI期刊详情-国际管理科学与工程技术协会（IAMSET）',
		},
	},
	{
		path: "/journalDetailsESCI",
		name: "journalDetailsESCI",
		component: () => import("../views/journal/journalDetailsESCI.vue"),
		meta: {
			index: 111,
			title: 'ESCI期刊详情-国际管理科学与工程技术协会（IAMSET）',
		},
	},
	{
		path: "/journalDetails1",
		name: "journalDetails1",
		component: () => import("../views/journal/journalDetails1.vue"),
		meta: {
			index: 27,
			title: '期刊详情-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/journalismDetails",
		name: "journalismDetails",
		component: () => import("../views/journal/journalismDetails.vue"),
		meta: {
			index: 11,
			title: '期刊详情-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/translation",
		name: "translation",
		component: () => import("../views/service/translation.vue"),
		meta: {
			index: 12,
			title: '论文翻译-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/touchUp",
		name: "touchUp",
		component: () => import("../views/service/touchUp.vue"),
		meta: {
			index: 13,
			title: '论文润色-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/duplicate",
		name: "duplicate",
		component: () => import("../views/service/duplicate.vue"),
		meta: {
			index: 14,
			title: '查重服务-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/searchProof",
		name: "searchProof",
		component: () => import("../views/service/searchProof.vue"),
		meta: {
			index: 15,
			title: '检索证明-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/about",
		name: "about",
		component: () => import("../views/about.vue"),
		meta: {
			index: 16,
			title: '关于我们-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/learning",
		name: "learning",
		component: () => import("../views/notice/learning.vue"),
		meta: {
			index: 17,
			title: '学术动态-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/important",
		name: "important",
		component: () => import("../views/notice/important.vue"),
		meta: {
			index: 18,
			title: '干货分享-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/progress",
		name: "progress",
		component: () => import("../views/notice/progress.vue"),
		meta: {
			index: 19,
			title: '论文进展-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/Journalism",
		name: "Journalism",
		component: () => import("../views/notice/Journalism.vue"),
		meta: {
			index: 20,
			title: '协会新闻-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/noticeDetails",
		name: "noticeDetails",
		component: () => import("../views/notice/noticeDetails.vue"),
		meta: {
			index: 21,
			title: '详情-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/resourceDow",
		name: "resourceDow",
		component: () => import("../views/notice/resourceDow.vue"),
		meta: {
			index: 22,
			title: '资源下载-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/contribution",
		name: "contribution",
		component: () => import("../views/contribution.vue"),
		meta: {
			index: 23,
			title: '在线投稿-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/problem",
		name: "problem",
		component: () => import("../views/problem.vue"),
		meta: {
			index: 24,
			title: '常见问题-国际管理科学与工程技术协会（IAMSET）'
		},
	},
	{
		path: "/articleDetails",
		name: "articleDetails",
		component: () => import("../views/notice/articleDetails.vue"),
		meta: {
			index: 25,
			title: '详情-国际管理科学与工程技术协会（IAMSET）'
		},
	}
];


const router = createRouter({
	// history: createWebHashHistory(),
	history: createWebHistory(),
	routes,
	
});

export default router;
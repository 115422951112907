<template>
	<div>
		<div>
			<el-carousel height="400px" v-if="bannerList[0]">
				<el-carousel-item v-for="(item,itemIndex) in bannerList" :key="itemIndex">
					<el-link :underline='false' :href="item.fieldB" target="view_window">
						<el-image style="width: 100%;" :src="item.fieldA"></el-image>
					</el-link>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="page-content">
			<el-divider class="eldivider"></el-divider>
			<p style="font-weight: bold;font-size: 22px;">服务项目</p>
			<p style="font-size: 14px;color: #b2b2b2;margin-top: -18px;">
				<span>Services</span>
			</p>
		</div>
		<div class="index-8-bg">
			<div class="page-content metting-card" style="justify-content: space-between;padding:20px;">
				<el-card class="box-card" shadow="hover"
					style="width: 233px;height: 290px;text-align: center;margin-top: 20px;">
					<el-image style="" :src="require('@/assets/images/qikjuoyn.png')"></el-image>
					<p style="font-weight: bold;">期刊索引</p>
					<div style="line-height: 8px;color: #7d7d7d;font-size: 12px;">
						<p>数万本期刊资源</p>
						<p>期刊信息一键获悉</p>
					</div>
					<el-link :underline='false' href="/journalSci">
						<el-button type="danger" class="qikjuoyn" plain size="mini"
							style="width: 126px;margin-top: 15px;">立即索引
						</el-button>
					</el-link>
				</el-card>
				<el-card class="box-card" shadow="hover"
					style="width: 233px;height: 290px;text-align: center;margin-top: 20px;">
					<el-image style="" :src="require('@/assets/images/gojihvyi.png')"></el-image>
					<p style="font-weight: bold;">学术会议</p>
					<div style="line-height: 8px;color: #7d7d7d;font-size: 12px;">
						<p>国内外知名专家学者</p>
						<p>多学科领域会议</p>
					</div>
					<el-link :underline='false' href="/meeting">
						<el-button type="danger" class="gojihvyi" plain size="mini"
							style="width: 126px;margin-top: 15px;">立即参会
						</el-button>
					</el-link>
				</el-card>
				<el-card class="box-card" shadow="hover"
					style="width: 233px;height: 290px;text-align: center;margin-top: 20px;">
					<el-image style="" :src="require('@/assets/images/index_5.png')"></el-image>
					<p style="font-weight: bold;">论文翻译</p>
					<div style="line-height: 8px;color: #7d7d7d;font-size: 12px;">
						<p>专家博士中译英</p>
						<p>专业学术编辑团队</p>
					</div>
					<el-link :underline='false' href="/translation">
						<el-button type="danger" plain size="mini" style="width: 126px;margin-top: 15px;">立即翻译
						</el-button>
					</el-link>
				</el-card>
				<el-card class="box-card" shadow="hover"
					style="width: 233px;height: 290px;text-align: center;margin-top: 20px;">
					<el-image style="" :src="require('@/assets/images/index_6.png')"></el-image>
					<p style="font-weight: bold;">论文润色</p>
					<div style="line-height: 8px;color: #7d7d7d;font-size: 12px;">
						<p>多年论文润色经验</p>
						<p>欧美母语编辑润色论文</p>
					</div>
					<el-link :underline='false' href="touchUp">
						<el-button type="warning" plain size="mini" style="width: 126px;margin-top: 15px;">立即润色
						</el-button>
					</el-link>
				</el-card>
				<el-card class="box-card" shadow="hover"
					style="width: 233px;height: 290px;text-align: center;margin-top: 20px;">
					<el-image style="" :src="require('@/assets/images/index_7.png')"></el-image>
					<p style="font-weight: bold;">查重服务</p>
					<div style="line-height: 8px;color: #7d7d7d;font-size: 12px;">
						<p>算法精准高效</p>
						<p>支持多种检测功能</p>
					</div>
					<el-link :underline='false' href="duplicate">
						<el-button type="success" plain size="mini" style="width: 126px;margin-top: 15px;">立即查重
						</el-button>
					</el-link>
				</el-card>
				<el-card class="box-card" shadow="hover"
					style="width: 233px;height: 290px;text-align: center;margin-top: 20px;">
					<el-image style="" :src="require('@/assets/images/index_8.png')"></el-image>
					<p style="font-weight: bold;">检索证明</p>
					<div style="line-height: 8px;color: #7d7d7d;font-size: 12px;">
						<p>代开检索证明</p>
						<p>方便快捷又安全</p>
					</div>
					<el-link :underline='false' href="searchProof">
						<el-button type="primary" plain size="mini" style="width: 126px;margin-top: 15px;">立即查询
						</el-button>
					</el-link>
				</el-card>
			</div>
		</div>
		<div class="page-content" style="margin-top: 20px;">
			<el-image style="width: 100%;" :src="require('@/assets/images/index_9.png')"></el-image>
		</div>

		<div class="page-content">
			<div>
				<div style="width: 100%;">
					<div>
						<el-divider class="eldivider"></el-divider>
						<p style="font-weight: bold;font-size: 22px;">期刊推荐</p>
						<!-- <p style="font-size: 14px;color: #b2b2b2;margin-top: -18px;">
							<span>Cooperative Journals</span>
							<el-link :underline="false" href="/journalEi" style="float: right;margin-right: 20px;">查看更多>
							</el-link>
						</p> -->
					</div>
					<div class="metting-card">
						<div style="width: 500px;text-align: center;">
							<el-link class="card-a" :underline='false' href="/journalSci">
								<el-image :src="require('@/assets/images/index_1.jpg')"></el-image>
							</el-link>
						</div>
						<div style="width: 500px;text-align: center;">
							<el-link class="card-a" :underline='false' href="/journalSci">
								<el-image :src="require('@/assets/images/index_2.jpg')"></el-image>
							</el-link>
						</div>
						<!-- 暂时不要 -->
						<!-- <div style="width: 500px;text-align: center;">
							<el-link class="card-a" :underline='false' href="/journalEi">
								<el-image :src="require('@/assets/images/index_3.jpg')"></el-image>
							</el-link>
						</div> -->
						<div style="width: 500px;text-align: center;">
							<el-link class="card-a" :underline='false' href="/journalAhci">
								<el-image :src="require('@/assets/images/A&HCI.jpg')"></el-image>
							</el-link>
						</div>
						<div style="width: 500px;text-align: center;">
							<el-link class="card-a" :underline='false' href="/journalEsci">
								<el-image :src="require('@/assets/images/ESCI.jpg')"></el-image>
							</el-link>
						</div>
					</div>
					<div>
						<el-divider class="eldivider"></el-divider>
						<p style="font-weight: bold;font-size: 22px;">学术会议</p>
						<!-- <p style="font-size: 14px;color: #b2b2b2;margin-top: -18px;">
							<span>Academic Conference</span>
							<el-link :underline="false" href="/meeting" style="float: right;margin-right: 20px;">查看更多>
							</el-link>
						</p> -->
					</div>
					<div v-loading="meetingLoading">
						<div class="top metting-card card-b cursor">
							<el-card shadow="hover" class="box-card card-c" :body-style="{ padding: '0px' }"
								v-for="(item,index) in meetintList" :key="index" @click="meetingDetails(index)">
								<el-image style="width: 280px;height: 140px;" :src="item.fieldA"></el-image>
								<div style="padding: 14px;">
									<div class="two">
										{{item.fieldC}}
										<el-image :src="require('@/assets/images/hot.gif')" class="index-meeting"
											v-if="item.fieldG=='火'">
										</el-image>
									</div>
									<div class="two">
										<i class="el-icon-time" style="margin-right: 5px;"></i>
										<span>{{item.fieldD}}</span>
									</div>
									<div class="two">
										<i class="el-icon-location-outline" style="margin-right: 5px;"></i>
										<span>{{item.fieldE}}</span>
									</div>
								</div>
							</el-card>
						</div>
					</div>
					<div>
						<el-divider class="eldivider"></el-divider>
						<p style="font-weight: bold;font-size: 22px;">期刊分享</p>
						<p style="font-size: 14px;color: #b2b2b2;margin-top: -18px;">
							<span>Call for Papers</span>
							<!-- <el-link :underline="false" href="javascript:void(0);" style="float: right;">查看更多></el-link> -->
						</p>
					</div>
					<div style="" v-loading="iamsetOtherLoading">
						<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top: 3%">
							<el-tab-pane label="SSCI/SCI/EI" name="SSCI/SCI/EI">
								<div v-for="(item,index) in articleList" :key="index">
									<p>
										<el-link v-if="item.fieldD!=''" :underline='false' :href="item.fieldD"
											target="view_window"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link>
										<el-link v-else :underline='false' href="javascript:void(0)"
											@click="articleDetails(index)"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link>
									</p>
									<div style="color: #7d7d7d;font-size: 14px;">
										<div class="two">
											{{item.fieldB}}
										</div>
										<div style="margin-top: 20px;">
											来源：{{item.fieldC}} 丨 {{item.createDate}}
										</div>
									</div>
									<el-divider></el-divider>
								</div>
							</el-tab-pane>
						</el-tabs>

						<div v-if="articleShow" style="margin: auto;">
							<el-empty description="暂无数据"></el-empty>
						</div>
						<div v-if="!articleShow" class="pagination" style="text-align: center;">
							<el-pagination background layout="prev, pager, next" :total="dataCount"
								@current-change="handleCurrentChange">
							</el-pagination>
						</div>
					</div>
				</div>

			</div>
		</div>

		<div class="page-content">
			<el-divider class="eldivider"></el-divider>
			<p style="font-weight: bold;font-size: 22px;">学会动态</p>
			<p style="font-size: 14px;color: #b2b2b2;margin-top: -18px;">
				<span>Association Notice</span>
				<el-link :underline="false" href="/learning" style="float: right;">查看更多></el-link>
				<!-- margin-right: 26%; -->
			</p>
		</div>
		<div class="page-content">
			<div style="display: flex;">
				<div style="width: 100%;" class="noticeLoading">
					<el-tabs v-model="activeName1" @tab-click="handleClick1" style="margin-top: 3%;">
						<el-tab-pane label="科研要闻" name="科研要闻">
							<div v-for="(item,index) in noticeList" :key="index">
								<div class="num">{{index+1}}</div>
								<p style="color: #18305b;font-weight: normal;">
									<el-link v-if="item.fieldD!=''" :underline='false' :href="item.fieldD"
										target="view_window"
										style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
									</el-link>
									<el-link v-else :underline='false' href="javascript:void(0)"
										@click="noticeDetails(index)"
										style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
									</el-link>
								</p>
								<div style="padding-left: 36px;color: #7d7d7d;font-size: 14px;">
									<div class="two">
										{{item.fieldB}}
									</div>
									<div style="margin-top: 20px;">
										来源：{{item.fieldC}} 丨 {{item.createDate}}
									</div>
								</div>
								<el-divider></el-divider>
							</div>
						</el-tab-pane>
						<el-tab-pane label="干货分享" name="干货分享">
							<div v-for="(item,index) in noticeList" :key="index">
								<div class="num">{{index+1}}</div>
								<p style="color: #18305b;font-weight: normal;">
									<el-link v-if="item.fieldD!=''" :underline='false' :href="item.fieldD"
										target="view_window"
										style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
									</el-link>
									<el-link v-else :underline='false' href="javascript:void(0)"
										@click="noticeDetails(index)"
										style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
									</el-link>
								</p>
								<div style="padding-left: 36px;color: #7d7d7d;font-size: 14px;">
									<div class="two">
										{{item.fieldB}}
									</div>
									<div style="margin-top: 20px;">
										来源：{{item.fieldC}} 丨 {{item.createDate}}
									</div>
								</div>
								<el-divider></el-divider>
							</div>
						</el-tab-pane>
						<el-tab-pane label="协会新闻" name="协会新闻">
							<div v-for="(item,index) in noticeList" :key="index">
								<div class="num">{{index+1}}</div>
								<p style="color: #18305b;font-weight: normal;">
									<el-link v-if="item.fieldD!=''" :underline='false' :href="item.fieldD"
										target="view_window"
										style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
									</el-link>
									<el-link v-else :underline='false' href="javascript:void(0)"
										@click="noticeDetails(index)"
										style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
									</el-link>
								</p>
								<div style="padding-left: 36px;color: #7d7d7d;font-size: 14px;">
									<div class="two">
										{{item.fieldB}}
									</div>
									<div style="margin-top: 20px;">
										来源：{{item.fieldC}} 丨 {{item.createDate}}
									</div>
								</div>
								<el-divider></el-divider>
							</div>
						</el-tab-pane>
					</el-tabs>
					<div v-if="noticeShow" style="margin: auto;">
						<el-empty description="暂无数据"></el-empty>
					</div>
				</div>

			</div>
		</div>
		<div class="page-content">
			<el-divider class="eldivider"></el-divider>
			<p style="font-weight: bold;font-size: 22px;">友情链接</p>
			<p style="font-size: 14px;color: #b2b2b2;margin-top: -18px;">
				<span>Friendly link</span>
				<!-- <el-link :underline="false" href="javascript:void(0);" style="float: right;">查看更多></el-link> -->
			</p>
		</div>
		<div class="page-content card-d" style="">
			<!-- <div>◆ 友情链接</div> -->
			<div style="display: flex;flex-wrap: wrap;flex-direction: column; padding-left: 60px;">

				<el-card class="bottomCard">
					<div v-for="(item,index) in publisher1.slice(0,7)">
						<el-link :underline='false' :href="item.web" target="view_window" :key="index">
							<div class="box-card" style="height: 70px;margin-top: 20px;margin-right: 20px;">
								<el-image :src="item.src"></el-image>
							</div>
						</el-link>
					</div>
				</el-card>

				<el-card class="bottomCard">
					<div v-for="(item,index) in publisher1.slice(7,21)">
						<el-link :underline='false' :href="item.web" target="view_window" :key="index">
							<div class="box-card" style="height: 70px;margin-top: 20px;margin-right: 20px;">
								<el-image :src="item.src" style="height: 70px"></el-image>
							</div>
						</el-link>
					</div>
				</el-card>

				<el-card class="bottomCard">
					<div v-for="(item,index) in publisher1.slice(21)">
						<el-link :underline='false' :href="item.web" target="view_window" :key="index">
							<div class="box-card" style="height: 70px;margin-top: 20px;margin-right: 20px;">
								<el-image :src="item.src"></el-image>
							</div>
						</el-link>

					</div>
				</el-card>


				<!-- v-for="(item,index) in publisher1" -->


			</div>
		</div>
	</div>
</template>

<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				iamsetOtherLoading: false,
				meetingLoading: false,
				articleShow: false,
				noticeShow: false,
				dataCount: 0,
				currentPage: 1,
				activeName: "SSCI/SCI/EI",
				activeName1: "科研要闻",
				rotation: [
					"http://compendex.ac.cn/localFile/indexbanner.jpg",
					"http://compendex.ac.cn/localFile/indexbanner.jpg",
				],
				meetintList: [],
				publisher1: [{
						src: require('@/assets/images/rjlxLogo.png'),
						web: "http://www.rjlx.com.cn/"
					},
					{
						src: require('@/assets/images/xsgz.png'),
						web: "http://www.acagrid.com/"
					},
					{
						src: require('@/assets/images/doi.png'),
						web: "https://www.doi.org/"
					},
					{
						src: require('@/assets/images/orcid.png'),
						web: "https://orcid.org/"
					},
					{
						src: require('@/assets/images/cro.png'),
						web: "https://www.crossref.org/"
					},
					{
						src: require('@/assets/images/rese.png'),
						web: "https://www.thomsonreuters.com/en.html"
					},
					//one row 分界线--------------------------
					{
						src: require('@/assets/images/elseviep.png'),
						web: "https://www.elsevier.com/",
					},
					{
						src: require('@/assets/images/springerLogo.png'),
						web: "https://link.springer.com/"
					},
					{
						src: require('@/assets/images/wiley.png'),
						web: "https://www.wiley.com/"

					},
					{
						src: require('@/assets/images/tay.png'),
						web: "https://www.tandfonline.com"
					},
					{
						src: require('@/assets/images/sage.png'),
						web: "https://journals.sagepub.com/"
					},
					{
						src: require('@/assets/images/bnj.png'),
						web: "https://www.bmj.com/"
					},
					{
						src: require('@/assets/images/inder.png'),
						web: "https://www.inderscience.com/"
					},
					{
						src: require('@/assets/images/acmLogo.png'),
						web: "https://dl.acm.org/journals"
					},
					{
						src: require('@/assets/images/ieeeLogo.png'),
						web: "https://www.ieee.org/publications/index.html"
					},
					{
						src: require('@/assets/images/frontiersinLogo.png'),
						web: "https://www.frontiersin.org/"
					},
					{
						src: require('@/assets/images/mdpiLogo.png'),
						web: "https://www.mdpi.com"
					},
					{
						src: require('@/assets/images/hindawiLogo.png'),
						web: "https://www.hindawi.com/"
					},
					{
						src: require('@/assets/images/cam.png'),
						web: "https://www.cambridge.org/"
					},
					{
						src: require('@/assets/images/ws.png'),
						web: "https://www.worldscientific.com/"
					},
					//two row 分界线-----------------------------
					{
						src: require('@/assets/images/clar.png'),
						web: "https://clarivate.com/"
					},



					{
						src: require('@/assets/images/mjlLogo.png'),
						web: "https://mjl.clarivate.com/home"
					},




					{
						src: require('@/assets/images/villageLogo.png'),
						web: "https://www.engineeringvillage.com/search/quick.url"
					},


					{
						src: require('@/assets/images/scopus.png'),
						web: "https://www.scopus.com/"
					}



				],

				articleList: [],
				noticeList: [],
				bannerList: [],
			};

		},
		methods: {
			noticeDetails(index) {
				this.$router.push({
					name: "noticeDetails",
					params: this.noticeList[index]
				});
				// let routeData = this.$router.resolve({
				// 	name: "noticeDetails",
				// 	params: this.noticeList[index]
				// });
				// window.open(routeData.href, 'view_window');
			},
			articleDetails(index) {
				this.$router.push({
					name: "articleDetails",
					params: this.articleList[index]
				});
			},
			journalDetails(index) {
				this.$router.push({
					name: "journalDetails",
					params: this.randomJournalList[index]
				});
			},
			meetingDetails(index) {
				window.open(this.meetintList[index].fieldB);
			},
			handleClick() {
				if (this.activeName == '英文普刊') {
					this.$router.push({
						name: "journalEn",
					});
				} else {
					this.queryIamsetOtherData()
				}
			},
			handleClick1() {
				this.queryIamsetOtherData1()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.queryIamsetOtherData()
			},
			queryIamsetOtherData() {
				let _this = this
				_this.iamsetOtherLoading = true
				let paramMap = {
					page: this.currentPage,
					fieldH: this.activeName,
					classification: "article"
				};
				$http
					.post("queryIamsetOtherData", paramMap)
					.then(function(res) {
						_this.iamsetOtherLoading = false
						if (res.data.length == 0) {
							_this.articleShow = true
						} else {
							_this.articleShow = false
						}
						_this.articleList = res.data;
						_this.dataCount = res.dataCount;
						_this.currentPage = res.pageNo;

					})
					.catch(function() {});
			},
			queryIamsetOtherData1() {
				let _this = this
				let paramMap = {
					page: 1,
					pageSize: 3,
					fieldH: this.activeName1 == '科研要闻' ? '学术动态' : this.activeName1,
					classification: "notice"
				};
				$http
					.post("queryIamsetOtherData", paramMap, "noticeLoading")
					.then(function(res) {
						if (res.data.length == 0) {
							_this.noticeShow = true
						} else {
							_this.noticeShow = false
						}
						_this.noticeList = res.data;

					})
					.catch(function() {});
			},
			queryIamsetOtherData2() {
				let _this = this
				_this.meetingLoading = true
				let paramMap = {
					page: 1,
					pageSize: 20,
					classification: "meeting"
				};
				$http
					.post("queryIamsetOtherData", paramMap)
					.then(function(res) {
						_this.meetingLoading = false
						if (res.data.length == 0) {
							_this.noticeShow = true
						} else {
							_this.noticeShow = false
						}
						_this.meetintList = res.data;

					})
					.catch(function() {});
			},
			queryIamsetOtherData3() {
				let _this = this
				let paramMap = {
					page: 1,
					pageSize: 20,
					classification: "indexBanner"
				};
				$http
					.post("queryIamsetOtherData", paramMap)
					.then(function(res) {
						_this.bannerList = res.data;
					})
					.catch(function() {});
			},

		},

		created() {
			this.queryIamsetOtherData3()
			this.queryIamsetOtherData2()
			this.queryIamsetOtherData()
			this.queryIamsetOtherData1()

		},
		mounted() {

		},
		watch: {

		},
		//是否引入头部和底部
		// created: function () {
		//   this.$emit("header", false);
		// },
		// created: function () {
		//   this.$emit("Footer", false);
		// },
	};
</script>
<!-- 内部样式更改 -->
<style lang="scss">
	.bottomCard {
		margin-top: 20px;
		padding: 0px 0px 25px 20px;

		&>.el-card__body {
			display: flex;
		}
	}

	.bottomCard:nth-of-type(2) {
		&>.el-card__body {
			display: flex;
			flex-wrap: wrap;
		}
	}
</style>
<style lang="scss" scoped>
	.eldivider {
		margin: 15px;
		background-color: #ff5722;
		float: left;
		height: 5px;
		width: 22px;
	}

	.metting-card {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 20px;
	}

	.card-b {
		.el-card {
			width: 280px;
		}

		.card-c {
			margin-top: 20px;
			margin-right: 35px;
		}

		// .card-c:not(:nth-child(5n)) {
		// 	margin-right: calc(11% / 4);
		// }
	}

	.card-a {
		margin-top: 60px;
	}

	// .card-a:not(:nth-child(3n)) {
	// 	margin-right: calc(28% / 3);
	// }

	.num {
		float: left;
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-color: #ff5722;
		// background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%);
		background-blend-mode: normal, normal;
	}

	.card-d {
		.el-card {
			--el-card-padding: 0;
		}
	}

	.index-8-bg {
		// height: 236px;
		background-image: url("../assets/images/index_10.png");
	}

	.el-button--danger.is-plain {
		color: #e60012;
		background: #ffffff;
		border-color: #e60012;
	}

	.el-button--danger.is-plain:focus,
	.el-button--danger.is-plain:hover {
		background: #e60012;
		border-color: #e60012;
		color: #fff;
	}

	.el-button--warning.is-plain {
		color: #ff5722;
		background: #ffffff;
		border-color: #ff5722;
	}

	.el-button--warning.is-plain:focus,
	.el-button--warning.is-plain:hover {
		background: #ff5722;
		border-color: #ff5722;
		color: #fff;
	}

	.el-button--success.is-plain {
		color: #178d75;
		background: #ffffff;
		border-color: #178d75;
	}

	.el-button--success.is-plain:focus,
	.el-button--success.is-plain:hover {
		background: #178d75;
		border-color: #178d75;
		color: #fff;
	}

	.el-button--primary.is-plain {
		color: #e4007f;
		background: #ffffff;
		border-color: #e4007f;
	}

	.el-button--primary.is-plain:focus,
	.el-button--primary.is-plain:hover {
		background: #e4007f;
		border-color: #e4007f;
		color: #fff;
	}

	.el-button--danger.is-plain.gojihvyi {
		color: #00A0E9;
		background: #ffffff;
		border-color: #00A0E9;
	}

	.el-button--danger.is-plain.gojihvyi:focus,
	.el-button--danger.is-plain.gojihvyi:hover {
		background: #00A0E9;
		border-color: #00A0E9;
		color: #fff;
	}

	.el-button--danger.is-plain.qikjuoyn {
		color: #920783;
		background: #ffffff;
		border-color: #920783;
	}

	.el-button--danger.is-plain.qikjuoyn:focus,
	.el-button--danger.is-plain.qikjuoyn:hover {
		background: #920783;
		border-color: #920783;
		color: #fff;
	}
</style>
<style lang="scss">
	.index-meeting .el-image__inner {
		vertical-align: bottom !important;
	}
</style>